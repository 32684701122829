<template>
  <div class="dutyInfos-managet">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="值班管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 用户 -->
        <ml-input
          prop="userNameLike"
          placeholder="请输入用户"
          style="margin-bottom: 0"
          label="用户名:"
          v-model="searchData.userNameLike"
        />
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0, marginRight: '40px' }"
          @change="searchFn"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 添加账号 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          :showSubmit="false"
          cancelText="添加值班日历"
          cancelIcon="el-icon-plus"
          cancelType="primary"
          @click-cancel="clickPush"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="班次一"
          prop="userName"
          min-width="20%"
        >
          <template #default="scope">
            {{ getClassUserNames(scope.row.dutyItemUsers, 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="班次二"
          prop="userName"
          min-width="20%"
        >
          <template #default="scope">
            {{ getClassUserNames(scope.row.dutyItemUsers, 2) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="班次三"
          prop="userName"
          min-width="20%"
        >
          <template #default="scope">
            {{ getClassUserNames(scope.row.dutyItemUsers, 3) }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" priop="dutyId" min-width="25%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickPush(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              v-if="isSuperAdmin"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickView(scope.$index, scope.row)"
            >
              查看值班日历
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteDtyInfosManageDialogRef"
      content=""
      @click-submit="submitDeleteDtyInfosManage"
    >
      <template #describe>
        <div>
          <div>{{ deleteDtyInfosManageData.content }}</div>
          <div class="describe">{{ deleteDtyInfosManageData.describe }}</div>
        </div>
      </template>
    </ml-dialog>
    <!-- 添加提示框 -->
    <ml-dialog
      width="400px"
      ref="addDtyInfosManageDialogRef"
      :title="addDtyInfosManageData.title"
      @click-submit="submitAddDtyInfosManageDislog"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="addDtyInfosManageForm"
          :rules="addDtyInfosManageFormRule"
          ref="addDtyInfosManageFormRef"
        >
          <!-- 阿米巴 -->
          <ml-select
            prop="companyId"
            placeholder="请选择阿米巴"
            :options="unitOptions"
            label="阿米巴:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            v-model="addDtyInfosManageForm.companyId"
            @change="selectCompany"
          />
          <!-- 所属项目 -->
          <ml-select
            prop="projectId"
            placeholder="请选所属项目"
            :options="addDtyInfosManageFormProjectData"
            label="所属项目:"
            keyName="projectId"
            labelName="projectName"
            valueName="projectId"
            v-model="addDtyInfosManageForm.projectId"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getTreeString,
  getProjectsString,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'DtyInfosManage',
  setup() {
    const router = useRouter()

    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    
    // 是否是超级管理员
    const isSuperAdmin = computed(() => getters.getIsSuperAdmin)

    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      userNameLike: '',
      companyId: '',
      projectId: ''
    })

    // 单位配置
    const unitOptions = ref([])

    // 所属项目配置
    const projectData = ref([])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    // 监听
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        }
      }
    )

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getDutyInfos() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getDutyInfos() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getDutyInfos = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.userNameLike = replacePerCent(searchData.userNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetDutyInfos',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getDutyInfos()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 添加值班
    // form
    const addDtyInfosManageFormProjectData = ref([])
    const addDtyInfosManageFormRef = ref()
    const addDtyInfosManageForm = reactive({
      companyId: '',
      projectId: ''
    })
    const selectCompany = () => {
      addDtyInfosManageForm.projectId = ''
    }
    const addDtyInfosManageFormRule = {
      companyId: [{ required: true, message: '请选择阿米巴 ', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }]
    }
    // 处理所属项目
    const getaddDtyInfosManageFormProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      addDtyInfosManageFormProjectData.value = data || []
    }

    // 监听
    watch(
      () => addDtyInfosManageForm.companyId,
      newvalue => {
        if (newvalue) {
          getaddDtyInfosManageFormProjectData(newvalue)
        }
      }
    )

    // dialog
    const addDtyInfosManageDialogRef = ref()
    const addDtyInfosManageData = reactive({
      title: '添加值班日历'
    })
    const clickPush = (index, row) => {
      addDtyInfosManageForm.companyId = ''
      addDtyInfosManageForm.projectId = ''
      if (row) {
        addDtyInfosManageForm.companyId = row.companyId || ''
        addDtyInfosManageForm.projectId = row.projectId || ''
      }
      addDtyInfosManageDialogRef.value.showDialog = true
    }
    const submitAddDtyInfosManageDislog = () => {
      addDtyInfosManageFormRef.value.CustomFormRef.validate()
        .then(() => {
          dispatch('fetchAddDutyInfo', addDtyInfosManageForm).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getDutyInfos() : (tabberData.page = 1)
              addDtyInfosManageDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    // 查看日历
    const clickView = (index, row) => {
      const tDate = new Date()
      const year = tDate.getFullYear()
      let month = tDate.getMonth() + 1
      let day = tDate.getDate()
      month = month < 10 ? `0${month}` : month
      day = day < 10 ? `0${day}` : day
      router.push({
        path: '/dutyInfosManagetDetails',
        query: { dutyId: row.dutyId, year, month, day }
      })
    }
    // 删除
    const deleteDtyInfosManageDialogRef = ref()
    const deleteDtyInfosManageData = reactive({
      content: '确认删除吗？',
      describe: '该操作将会删除值班下的所有数据'
    })
    const clickDelete = (index, row) => {
      deleteDtyInfosManageData.dutyId = row.dutyId
      deleteDtyInfosManageDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteDtyInfosManage = () => {
      const { dutyId } = deleteDtyInfosManageData
      dispatch('fetchDeleteDutyInfo', dutyId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getDutyInfos() : (tabberData.page = 1)
          deleteDtyInfosManageDialogRef.value.showDialog = false
        }
      })
    }

    const getClassUserNames = (dutyItemUsers = [], type = 1) => {
      let names = ''
      dutyItemUsers.map(item => {
        if (item.className === `班次${type}`) {
          names = item.userName
        }
      })
      return names
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []

      getDutyInfos()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickPush,
      clickView,
      clickDelete,
      deleteDtyInfosManageData,
      deleteDtyInfosManageDialogRef,
      submitDeleteDtyInfosManage,
      projectData,
      unitOptions,
      getTreeString,
      getProjectsString,
      addDtyInfosManageDialogRef,
      addDtyInfosManageData,
      submitAddDtyInfosManageDislog,
      addDtyInfosManageFormRef,
      addDtyInfosManageFormRule,
      addDtyInfosManageForm,
      addDtyInfosManageFormProjectData,
      selectCompany,
      getClassUserNames,
      isSuperAdmin
    }
  }
}
</script>

<style lang="scss" scoped>
.dutyInfos-mnage {
  @extend %params-global;
  .el-input__inner {
    width: 170px;
  }
}
</style>
